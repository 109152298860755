// 640px, 1150px, 1440px
$breakpoints-up: (
  "medium": "40em",
  "large": "71.875em",
  "xlarge": "90em",
);

// 639px, 1149px, 1439px
$breakpoints-down: (
  "small": "39.9375em",
  "medium": "71.8125em",
  "large": "89.9375em",
);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}
