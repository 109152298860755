@use "../util" as *;

.calculator {
  width: 100%;
  min-height: rem(481);
  max-height: rem(481);
  background: var(--white);
  border-radius: rem(15);
  box-shadow: 0px 32px 43px rgba(79, 166, 175, 0.200735);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: rem(45);
  padding: rem(45);

  @include breakpoint-down(medium) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    direction: rows;
    height: 100%;
    max-height: 100%;
    row-gap: rem(45);
    padding: rem(32);
  }

  &__form {
    height: 100%;
    display: grid;
    align-content: space-around;

    @include breakpoint-down(medium) {
      row-gap: rem(32);
    }
  }
}
