@use "../util" as *;

.calculations {
  background: var(--very-dark-cyan);
  border-radius: rem(15);
  padding: rem(40);
  display: grid;
  grid-template-rows: max-content 1fr;
}

.results {
  display: grid;
  row-gap: 1rem;

  &__result {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-self: start;
  }

  &__heading {
  }

  &__title {
    font-size: rem(16);
    color: var(--white);
    margin: 0;
  }

  &__subtitle {
    font-size: rem(13);
    color: hsla(183, 15%, 56%, 1);
    font-weight: bold;
  }

  &__calculation {
    justify-self: end;
    font-size: rem(48);
    color: var(--strong-cyan-2);
  }

  &__button {
    height: rem(48);
    border-radius: rem(5);
    border: none;
    text-transform: uppercase;
    // background: var(--strong-cyan-2);
    cursor: pointer;
    font-size: rem(20);
    font-weight: bold;
    color: var(--very-dark-cyan);
    transition: background 0.2s ease-in-out;
    &:hover {
      background: var(--strong-cyan) !important;
    }
  }
}
