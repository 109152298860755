:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --strong-cyan: hsl(173, 61%, 77%);
  --strong-cyan-2: hsla(172, 67%, 45%, 1);
  --very-dark-cyan: hsl(183, 100%, 15%);
  --dark-grayish-cyan: hsl(186, 14%, 43%);
  --light-grayish-cyan: hsla(185, 41%, 84%, 1);
  --grayish-cyan: hsla(185, 38%, 97%, 1);
  --white: hsl(0, 0%, 100%);
  --red: #e17457;
}
