@use "../util" as *;
html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: var(--font-body);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body);
  background: var(--light-grayish-cyan);
}

input {
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
