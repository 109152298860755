@use "../util" as *;

.container {
  padding: 0 rem(24);
  max-width: rem(920);
  margin: 0 auto;

  @include breakpoint-down(small) {
    padding: 0;
  }
}
