@use "../util" as *;

.logo {
  display: grid;
  justify-content: center;
  margin-top: rem(163);
  margin-bottom: rem(90);
  display: grid;
  justify-content: center;
}
