@use "../util" as *;

.input {
  width: 100%;

  &__title {
    font-size: rem(16);
    color: var(--dark-grayish-cyan);
    margin-bottom: rem(7);
    font-weight: 700;
    text-transform: capitalize;
  }

  &__heading {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__container {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: rem(15);
    left: rem(20);
  }

  &__field {
    width: 100%;
    background-color: var(--grayish-cyan);
    border: none;
    border-radius: rem(5);
    padding: rem(6) rem(15);
    height: rem(50);
    font-size: rem(24);
    color: var(--very-dark-cyan);
    font-weight: 700;
    text-align: right;
    cursor: pointer;
  }

  &__error {
    color: var(--red);
    display: none;
  }
  &__error-outline {
    border: 2px solid var(--red) !important;
  }

  &__show {
    display: block;
  }
}
