:root {
  --font-body: "Space Mono", monospace;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --strong-cyan: hsl(173, 61%, 77%);
  --strong-cyan-2: hsla(172, 67%, 45%, 1);
  --very-dark-cyan: hsl(183, 100%, 15%);
  --dark-grayish-cyan: hsl(186, 14%, 43%);
  --light-grayish-cyan: hsla(185, 41%, 84%, 1);
  --grayish-cyan: hsla(185, 38%, 97%, 1);
  --white: hsl(0, 0%, 100%);
  --red: #e17457;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: var(--font-body);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body);
  background: var(--light-grayish-cyan);
}

input {
  outline: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 57.5rem;
  margin: 0 auto;
}
@media (max-width: 39.9375em) {
  .container {
    padding: 0;
  }
}

.logo {
  display: grid;
  justify-content: center;
  margin-top: 10.1875rem;
  margin-bottom: 5.625rem;
  display: grid;
  justify-content: center;
}

.calculator {
  width: 100%;
  min-height: 30.0625rem;
  max-height: 30.0625rem;
  background: var(--white);
  border-radius: 0.9375rem;
  box-shadow: 0px 32px 43px rgba(79, 166, 175, 0.200735);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 2.8125rem;
  padding: 2.8125rem;
}
@media (max-width: 71.8125em) {
  .calculator {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    direction: rows;
    height: 100%;
    max-height: 100%;
    row-gap: 2.8125rem;
    padding: 2rem;
  }
}
.calculator__form {
  height: 100%;
  display: grid;
  align-content: space-around;
}
@media (max-width: 71.8125em) {
  .calculator__form {
    row-gap: 2rem;
  }
}

.input {
  width: 100%;
}
.input__title {
  font-size: 1rem;
  color: var(--dark-grayish-cyan);
  margin-bottom: 0.4375rem;
  font-weight: 700;
  text-transform: capitalize;
}
.input__heading {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.input__container {
  position: relative;
}
.input__icon {
  position: absolute;
  top: 0.9375rem;
  left: 1.25rem;
}
.input__field {
  width: 100%;
  background-color: var(--grayish-cyan);
  border: none;
  border-radius: 0.3125rem;
  padding: 0.375rem 0.9375rem;
  height: 3.125rem;
  font-size: 1.5rem;
  color: var(--very-dark-cyan);
  font-weight: 700;
  text-align: right;
  cursor: pointer;
}
.input__error {
  color: var(--red);
  display: none;
}
.input__error-outline {
  border: 2px solid var(--red) !important;
}
.input__show {
  display: block;
}

.tip__buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.875rem;
}
.tip__button {
  height: 3rem;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--white);
  background: var(--very-dark-cyan);
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.tip__button:hover {
  background: var(--strong-cyan);
  color: var(--very-dark-cyan);
}
.tip__selected {
  background: var(--strong-cyan);
  color: var(--very-dark-cyan);
}

.calculations {
  background: var(--very-dark-cyan);
  border-radius: 0.9375rem;
  padding: 2.5rem;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.results {
  display: grid;
  row-gap: 1rem;
}
.results__result {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-self: start;
}
.results__title {
  font-size: 1rem;
  color: var(--white);
  margin: 0;
}
.results__subtitle {
  font-size: 0.8125rem;
  color: #7e9ea0;
  font-weight: bold;
}
.results__calculation {
  justify-self: end;
  font-size: 3rem;
  color: var(--strong-cyan-2);
}
.results__button {
  height: 3rem;
  border-radius: 0.3125rem;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--very-dark-cyan);
  transition: background 0.2s ease-in-out;
}
.results__button:hover {
  background: var(--strong-cyan) !important;
}