@use "../util" as *;

.tip {
  &__buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(14);
  }

  &__button {
    height: rem(48);
    border: none;
    font-size: rem(24);
    font-weight: bold;
    color: var(--white);
    background: var(--very-dark-cyan);
    border-radius: rem(5);
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: var(--strong-cyan);
      color: var(--very-dark-cyan);
    }
  }

  &__selected {
    background: var(--strong-cyan);
    color: var(--very-dark-cyan);
  }
}
